import React from 'react';
import Layout from '../../layouts/Project';
import ProjectIntro from '../../content/ProjectIntro';
import CapsAndTech from '../../content/CapsAndTech';
import Text from '../../content/Text';
import Caption from '../../content/ImageCaption';
import { StaticImage as Image } from 'gatsby-plugin-image';
import Images from '../../content/Images';
import Video from '../../content/Video';
import Press from '../../content/Press';
import Credits from '../../content/Credits';

export const frontmatter = {
  title: 'The Day the World Changed',
  projectType: 'Experiences',
  description:
    'A VR documentary memorial for Hiroshima, where audiences experience the stories of survivors set within the Genbaku Dome, featured at Tribeca Film Festival.',
  client: ['Gabo Arora, Saschka Unseld'],
  release: '2018',
  capabilities: [
    'AR,VR',
    'Photogrammetry',
    'Software Engineering',
    'Experience Design'
  ],
  technology: ['Unity', 'Network Engineering', 'Graphics Programming'],
  credits: {
    Client: 'Tomorrow Never Knows',
    Partnership:
      'The Nobel Prize Foundation and the International Campaign to Abolish Nuclear Weapons (ICAN)',
    Directors: 'Saschka Unseld, Gabo Arora',
    'Other Credits': 'Ntropic',
    Engineering: 'Superbright'
  },
  order: 6
};

const Page = () => (
  <Layout title={frontmatter.title}>
    <ProjectIntro frontmatter={frontmatter} />

    <Images>
      <Image
        src="../../images/work/the-day-the-world-changed/TDTWC_01.jpg"
        alt="The Day The World Changed"
      />
      <Caption>
        An immersive VR memorial to the victims and survivors of the atomic
        bombing in Hiroshima, August 1945.
      </Caption>
    </Images>

    <CapsAndTech frontmatter={frontmatter} />

    <Text title="Opportunity" split={2} large>
      <p>
        Directors Gabo Arora, Saschka Unseld commissioned Superbright to design
        & develop a Social VR experience for the documentary piece 'The Day the
        World Changed'.
      </p>
      <p>
        In partnership with The Nobel Prize Foundation and the International
        Campaign to Abolish Nuclear Weapons (ICAN), the projects objective was
        to make a virtual memorial where audiences can collectively experience
        survivors stories at a digital iteration of Genbaku Dome in Hiroshima.
      </p>
    </Text>

    <Images>
      <Video vimeoId="307064886" vimeoCode="0592f43289" />
    </Images>
    <Text title="Solution" split={1}>
      <p>
        Superbright leveraged its Social VR technology to build a multiplayer
        experience for participants to visit the memorial in Virtual Reality,
        free to explore the environment as a group, whilst listening to the
        survivors stories.
      </p>
      <p>
        The Hiroshima Peace Memorial (known as the Genbaku Dome) which is the
        only structure left standing in the area where the first atomic bomb
        exploded on 6 August 1945, was rigorously scanned via photogrammetry so
        it could be accurately recreated in game-engine.
      </p>
      <p>
        Each audience member embodied a bespoke avatar, that was created to
        reflect the historical nature of the environment, enabling them to blend
        in become a part of the story.
      </p>
    </Text>

    <Images>
      <Image src="../../images/work/the-day-the-world-changed/TDTWC_05.jpg" alt="The Day The World Changed" /> 

      <Image src="../../images/work/the-day-the-world-changed/TDTWC_02.jpg" alt="The Day The World Changed" />
      <Caption> The VR experience supported three concurrent users in the same space for a shared experience. </Caption>

      <Image src="../../images/work/the-day-the-world-changed/TDTWC_04.jpg" alt="The Day The World Changed" />  
            
    </Images>

    <Text title="Output" large split={2}>
      <p>
        The final VR experience was a 16 minute multi-participant documentary
        that was unveiled at Tribeca festival which went on to became part of a
        much larger campaign to raise awareness for the mission of ICAN.
      </p>
      <p>
        The multiplayer technology enabled a shared experience, which for an
        emotionally delicate historical homage such as this, increased the
        empathy whilst making sure that audience members did not feel too
        isolated or uncomfortable.
      </p>
      <p>
        "The Day the World Changed brings to viewers the harrowing impressions
        of the victims and survivors of atomic bombings and nuclear arms testing
        through first-hand testimonies, data visualisations and innovative use
        of 3-D scanning and photogrammetry. The multi-user social VR experience
        combines and re-contextualises existing archival footage, data and
        testimonies in unprecedented ways to develop a new grammar to describe
        this most important of history lessons" said Directors Gabo Arora &
        Saschka Unseld.
      </p>
      <p>
        The project was promoted at Tribeca festival as well an inaugural event
        with Nobel Prize winner Beatrice Fihn, raising awareness for the mission
        of ICAN and the The Nobel Prize foundation.
      </p>
    </Text>
    <Press
      quotes={[
        {
          quote:
            'The harrowing, interactive virtual reality experience shines a light on the horror of nuclear war, and was produced in partnership with 2017 Peace Laureate @nuclearban',
          url: 'https://twitter.com/NobelPrize/status/987310421911789569',
          heading: 'The Nobel Prize',
          subheading: 'on Twitter',
          date: 'April, 2018'
        },
        {
          quote: 'THE DAY THE WORLD CHANGED',
          heading: 'Tribeca',
          url: 'https://tribecafilm.com/films/day-the-world-changed-2018'
        },
        {
          quote: 'The Day the World Changed Trailer- Tribeca 2018',
          heading: 'Vimeo',
          url: 'https://vimeo.com/266724374'
        },
        {
          quote: "The devastation of nuclear war is VR's latest reality check",
          heading: 'Engadget',
          url: 'https://www.engadget.com/2018-04-28-the-day-the-world-changed-vr-hiroshima-tribeca.html',
          date: 'April, 2018'
        },

        {
          quote:
            "It's important to remember that the amount of nuclear weapons that the world has is based on this original moment of fear and that we still live in the  shadow of that fear.",
          heading: 'Saschka Unseld',
          url: 'https://www.thedrum.com/creative-works/project/gabo-arora-and-saschka-unseld-nobel-media-the-day-the-world-changed',
          subheading: 'on The Drum',
          date: 'July, 2018'
        },
        {
          quote:
            'This experience uses virtual reality’s strengths in a way that makes viewers have a more visceral relationship with what is usually just a rational engagement with numbers, this time focusing on one of the most urgent issues of today',
          heading: 'Gabo Arora',
          subheading: 'on The Drum',
          url: 'https://www.thedrum.com/creative-works/project/gabo-arora-and-saschka-unseld-nobel-media-the-day-the-world-changed',
          date: 'July, 2018'
        }
      ]}
    />
    <Credits credits={frontmatter.credits} />
  </Layout>
);

export default Page;
